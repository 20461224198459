.tab-swiper{
    $btn-size: 47px;
    $btn-margin: 40px;
    .swiper{
        &::before{
            content: '';
            width: 100%;
            height: 1px;
            left: 0px;
            bottom: 0px;
            position: absolute;
            background-color: #707070;
        }
    }
    .swiper-holder{
        max-width: calc( 100% - ( #{ $btn-margin } * 2 ) - ( #{ $btn-size * 2 } ) );
    }
    .swiper-slide{
        width: fit-content;
        padding: 30px 50px;
        transition: all .35s ease-out;
        white-space: nowrap;
        &:hover{
            color: #3C3C3C;
        }
        &::before{
            content: '';
            width: calc( 100% - ( 20px * 2 ) );
            height: 2px;
            bottom: 0px;
            left: 20px;
            position: absolute;
            transition: background .35s ease-out;
            background-color: transparent;
        }
        &[aria-expanded="true"]{
            color: #3C3C3C;
            font-weight: 500;
            &::before{
                background-color: #3C3C3C;
            }
        }
        @media screen and ( min-width: map-get( $grid-breakpoints, lg ) ) {
            &:first-child{
                padding-left: 70px;
                &[aria-expanded="true"]{
                    &::before{
                        left: 40px;
                    }
                }
            }
            &:last-child{
                padding-right: 70px;
            }
            &:first-child,&:last-child{
                &::before{
                    width: calc( 100% - ( 20px * 2 ) - 20px );
                }
            }
        }
    }
    .slider-button{
        &.swiper-button-disabled{
            opacity: 0;
            pointer-events: none;
        }
    }
    @media screen and ( min-width: map-get( $grid-breakpoints, lg ) ) {
        .slider-button{
            &.next-button{
                margin-left: $btn-margin;
            }
            &.prev-button{
                margin-right: $btn-margin;
            }
        }
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, xl ) - 1 ) {
    .tab-swiper{
        .swiper-slide{
            padding: 20px 30px;
            &:last-child{
                padding-right: 45px;
            }
            &:first-child{
                padding-left: 45px;
            }
        }
    }
}
@media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ) {
    .tab-swiper{
        .swiper{
            &::before{
                bottom: 1px;
            }
        }
        .swiper-holder{
            max-width: 100%;
        }
        .swiper-slide{
            padding: 15px 20px;
            &::before{
                height: 3px;
            }
            &:first-child{
                padding-left: 30px;
                &::before{
                    left: 30px;
                }
            }
            &:last-child{
                padding-right: 30px;
                &::before{
                    right: 30px;
                }
            }
            &:first-child,&:last-child{
                &::before{
                    width: calc( 100% - ( 20px * 2 ) - 10px );
                }
            }
        }
        .slider-button{
            display: none !important;
        }
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, sm ) - 1 ) {
    .tab-swiper{
        padding: 0 !important;
        .swiper-slide{
            padding: 10px;
            &:first-child,&:last-child{
                &::before{
                    width: calc( 100% - ( 10px ) );
                }
            }
            &:first-child{
                padding-left: 23px;
                &::before{
                    left: 10px;
                }   
            }
            &:last-child{
                padding-right: 23px;
                &::before{
                    right: 10px;
                    left: unset;
                }
            }
            &::before{
                left: 9px;
                width: calc( 100% - ( 10px * 2 ) );
            }
        }
    }
}