  body{
      font-family: "Figtree", sans-serif !important;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
  }
  
  .obj-cover{
    object-fit: cover;
  }

  .img-regular{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .pr-50{
    padding-right: 50px;
  }
  
  // fonts
  // header 20-18
  .header-white-big{
    font-size: 20px;
    line-height: 27px;
    font-weight: 200;
    color: #FFFFFF;
  }

  .header-white-big-medium{
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    color: #FFFFFF;
  }

  .header-black-big-medium{
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    color: #3C3C3C;
  }
  
  .header-white{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF;
  }
  
  .header-blue{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #0075C9;
  }

  // title 60-40-20
  .title-big{
    font-size: 60px;
    line-height: 65px;
    font-weight: 300;
    color: #FFFFFF;
  }

  .title-big-gray{
    font-size: 60px;
    line-height: 65px;
    font-weight: 300;
    color: #3C3C3C;
  }
  
  .title-white{
    font-size: 40px;
    line-height: 48px;
    font-weight: 300;
    color: #FFFFFF;
  }
  
  .title-gray{
    font-size: 40px;
    line-height: 48px;
    font-weight: 300;
    color: #3C3C3C;
  }

  // desc 24-18
  .desc-big-white{
    font-size: 24px;
    line-height: 32px;
    font-weight: 200;
    color: #FFFFFF;
  }

  .desc-big-gray{
    font-size: 24px;
    line-height: 32px;
    font-weight: 200;
    color: #636569;
  }

  .desc-big-black{
    font-size: 24px;
    line-height: 32px;
    font-weight: 200;
    color: #3C3C3C;
  }

  .desc-gray{
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #767474;
  }

  .desc-black{
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #3C3C3C;
  }

  .desc-white{
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    color: #FFFFFF;
  }

  // number 40
  .number-big{
    font-size: 158px;
    line-height: 210px;
    font-weight: 400;
    color: #EFEFEF;
  }
  .number-blue{
    font-size: 40px;
    line-height: 53px;
    font-weight: 400;
    color: #0075C9;
  }

  // date 18-16
  .date-big{
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
    color: #888888;
  }
  .date{
    font-size: 16px;
    line-height: 21px;
    font-weight: 200;
    color: #3C3C3C;
  }
  .date-white{
    font-size: 16px;
    line-height: 21px;
    font-weight: 200;
    color: #FFF;
  }

  // name 17
  .name{
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 2px;
    font-weight: 500;
    color: #3C3C3C;
  }

  .medium{
    font-weight: 500;
  }

  @media screen and (max-width: 1900px) {
    
    .title-big{
      font-size: 48px;
      line-height: 56px;
    }
    
    .title-big-gray{
      font-size: 48px;
      line-height: 56px;
    }
    
    .title-white{
      font-size: 32px;
      line-height: 40px;
    }
    
    .title-gray{
      font-size: 32px;
      line-height: 40px;
    }
  
    // desc 24-18
    .desc-big-white{
      font-size: 21px;
      line-height: 24px;
    }
  
    .desc-big-gray{
      font-size: 21px;
      line-height: 24px;
    }
  
    .desc-big-black{
      font-size: 21px;
      line-height: 24px;
    }
  
    // number 40
    .number-big{
      font-size: 130px;
      line-height: 160px;
    }
    .number-blue{
      font-size: 32px;
      line-height: 40px;
    }
  
  }

  @media screen and (max-width: 1500px) {
    .title-big{
      font-size: 40px;
      line-height: 48px;
    }
    .title-big-gray{
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media screen and (max-width: 1200px) {
  
    // fonts
    // header
    .header-white-big{
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
    }

    .header-white-big-medium{
      font-size: 16px;
      line-height: 21px;
    }

    .header-black-big-medium{
      font-size: 16px;
      line-height: 21px;
    }
    
    .header-white{
      font-size: 16px;
      line-height: 21px;
    }
    
    .header-blue{
      font-size: 16px;
      line-height: 21px;
    }

    // title
    .title-big{
      font-size: 32px;
      line-height: 38px;
    }
    
    .title-big-gray{
      font-size: 32px;
      line-height: 38px;
    }

    .title-white{
      font-size: 28px;
      line-height: 36px;
    }
    
    .title-gray{
      font-size: 28px;
      line-height: 36px;
    }

    // desc
    .desc-big-white{
      font-size: 16px;
      line-height: 24px;
    }

    .desc-big-gray{
      font-size: 16px;
      line-height: 24px;
    }

    .desc-big-black{
      font-size: 16px;
      line-height: 24px;
    }

    .desc-gray{
      font-size: 16px;
      line-height: 24px;
    }

    .desc-black{
      font-size: 16px;
      line-height: 24px;
    }

    .desc-white{
      font-size: 16px;
      line-height: 24px;
    }

    // number
    .number-big{
      font-size: 86px;
      line-height: 115px;
    }

    // date
    .date-big{
      font-size: 16px;
      line-height: 21px;
    }

    // name 17
    .name{
      font-size: 16px;
      line-height: 21px;
    }

  }

  @media screen and (max-width: 993px) {
    .pr-50{
      margin-bottom: 0;
      padding-right: 0px;
    }
  }