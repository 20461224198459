.footer{
    padding: 0px;
    background-color: #F8F8F8;
    .footer-logo{
        img{
            max-width: 260px;
            width: 100%;
            height: auto;
        }
    }
    .flag-lang-selector{
        margin-top: 85px;
    }
    .footer-menu{
        margin-bottom: 180px;
        &-item{
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            color: #3C3C3C;
            padding: 10px 10px 10px 0px;
            font-weight: 500;
            &:hover{
                color: darken(#3C3C3C, 10);
            }
            @media screen and ( min-width: map-get( $grid-breakpoints, lg ) ){
                padding: 10px 10px;
            }
            @media screen and ( min-width: map-get( $grid-breakpoints, xl ) ){
                padding: 10px 20px;
                &:first-child{
                    padding-left: 0px;
                }
                &:last-child{
                    padding-right: 0px;
                }
            }
        }
        &-sub-item{
            margin: 0px 30px;
            color: #3C3C3C;
            @media screen and ( max-width: map-get( $grid-breakpoints, xl ) - 1 ) and ( min-width: map-get( $grid-breakpoints, lg ) ) {
                margin: 0px 10px;                
            }
            &:hover{
                color: darken(#3C3C3C, 10);
            }
            &:first-child{
                margin-left: 0px;
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .socail-buttons{
        .socail-circle-btn{
            margin: 0px 10px;
            transition: all .6s ease-out;
            &:last-child{
                margin-right: 0px;
                &:hover{
                    background-color: #ff0000;
                }
            }
            &:first-child{
                margin-left: 0px;
                &:hover{
                    background-color: #0a66c2;
                }
            }
            &:nth-child(2){
                &:hover{
                    background-color: #0866ff;
                }
            }
            &:nth-child(3){
                &:hover{
                    background-color: #e4405f;
                }
            }
            &:nth-child(4){
                &:hover{                
                    background-color: #000000 !important;
                }
            }
        }
    }
    .footer-top{
        padding: 90px 0px 80px;
    }
    .footer-bottom{
        border-top: 1px solid #D1D1D1;
        padding: 40px 0px 80px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        font-weight: 500;
        color: #3C3C3C;
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ){
    .footer{
        .footer-logo{
            img{
                max-width: 165px;
            }
        }
        .footer-menu{
            margin-top: 55px;
            margin-bottom: 50px;
            &-item{
                font-size: 16px;
            }
        }
        .flag-lang-selector{
            margin-top: 0px;
        }
        .footer-top{
            padding: 60px 0px 50px;
        }
        .footer-bottom{
            padding: 30px 0px;
            font-size: 13px;
        }
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, sm ) - 1 ){
    .footer{
        .socail-buttons{
            .socail-circle-btn{
                margin: 0px 4px;
            }
        }
    }
}

@media screen and ( max-width: 400px ){
    .footer{
        .socail-circle-btn{
            width: 44px;
            height: 44px;
            min-width: 44px;
            img{
                max-width: 18px;
                max-height: 18px;
            }
        }
    }
}