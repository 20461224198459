@media screen and ( max-width: map-get( $grid-breakpoints, xxl ) ) {
    #nav{
        .nav-logo{
            max-width: 168px;
            margin-right: 5.125vw;
        }
        .nav-link{
            font-size: 14px;
        }
        .mega-menu {
            a {
                font-size: 12px;
                line-height: 14px;
            }
            .sub-item-head {
                width: 100%;
            }
        }
        .container > div:last-child{
            a{
                margin-right: 1.125rem;
                &:last-of-type{
                    margin-left: 1.125rem;
                }
            }
        }
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, xl ) ) {
    #nav{
        .nav-logo{
            max-width: 142px;
        }
        .navbar-nav{
            width: auto;
        }
        .nav-item{
            display: none !important;
        }
        #langue{
            display: none !important;
        }
        .blue-btn-radius{
            display: none !important;
        }
        .container > div:last-child{
            a{
                margin-right: 1.125rem;
                &:last-of-type{
                    margin-left: 1.125rem;
                }
            }
        }
    }
}

//Min-width

@media screen and ( min-width: map-get($grid-breakpoints, lg) ){
    .nav-container {
        max-width: 940px!important;
    }
    #nav{
        .nav-item{
            .nav-link{
                display: flex;
            }
            .plus{
                display: block;
            }
            .minus{
                display: none;
            }
            &:hover{
                .nav-link{
                    border-bottom: 1px solid #FFF;
                }
                .plus{
                    display: none;
                }
                .minus{
                    display: block;
                }
                .mega-menu {
                    display: flex;
                }
            }
        }
    }
}

@media screen and ( min-width: map-get( $grid-breakpoints, xl ) ) {
    .nav-container {
        max-width: 1088px!important;
    }
}

@media screen and ( min-width: map-get( $grid-breakpoints, xxl ) ) {
    .nav-container {
        max-width: map-get( $container-max-widths,xxl )!important;
    }
}