.blue-btn-radius{
	background-color: #0075C9;
	border-radius: 20px;
	padding: 0 53px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		line-height: 40px;
		font-size: 18px;
		margin-bottom: 0;
		color: #FFFFFF !important;
	}
}

.blue-btn{
	background-color: #0075C9;
	border-radius: 2px;
	padding: 0 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		line-height: 54px;
		font-size: 16px;
		margin-bottom: 0;
		color: #FFFFFF !important;
	}
	img{
		margin-left: 17px;
	}
}

.blue-btn-big{
	background-color: #0075C9;
	border-radius: 2px;
	padding: 0 38px 0 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		line-height: 54px;
		font-size: 16px;
		margin-bottom: 0;
		color: #FFFFFF !important;
	}
	img{
		margin-left: 30px;
	}
}

.white-btn{
	background-color: #FFFFFF;
	border-radius: 2px;
	padding: 0 27px 0 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		line-height: 54px;
		font-size: 16px;
		margin-bottom: 0;
		color: #3C3C3C !important;
	}
	img{
		margin-left: 27px;
	}
}

.blur-btn{
    background-color: #00000000 !important;
    backdrop-filter: blur(30px) brightness(1.15);
	border-radius: 2px;
	padding: 0 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		line-height: 54px;
		font-size: 16px;
		margin-bottom: 0;
		color: #FFFFFF !important;
	}
	img{
		margin-left: 16px;
		transition: all .2s linear;
	}
	&:hover{
		img{
			transform: scale(1.3);
		}
	}
}

.load-more-btn{
	background-color: #0075C9;
	border-radius: 2px;
	padding: 0 44px 0 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
    height: fit-content;
	p{
		display: flex;
		align-items: center;
		span{
			margin-right: 16px;
			font-size: 24px;
			line-height: 54px;
		}
		font-size: 16px;
		margin-bottom: 0;
		color: #FFFFFF !important;
	}
}

.read-more-btn{
	display: flex;
    height: fit-content;
	p{
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 0;
		color: #0075C9;
	}
	img{
		margin-left: 17px;
		transition: all .2s linear;
	}
	&:hover{
		img{
			margin-left: 22px;
		}
	}
}

.number-download{
	display: flex;
    height: fit-content;
	p{
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 0;
		font-weight: 300;
		color: #0075C9;
	}
	img{
		margin-left: 15px;
		transition: all .2s linear;
	}
	&:hover{
		img{
			margin-left: 22px;
		}
	}
}

// animation
.animation-btn {
	
    &.btn-blue {
        background-image: linear-gradient(100deg, #01518a 50%, #0075C9 50%) !important;
		background-color: #0075C9 !important;
    }

    &.white-btn {
        background-image: linear-gradient(100deg, #e0e0e0 50%, #FFFFFF 50%) !important;
        background-color: #FFFFFF !important;
    }

    will-change: background-position !important;
    -webkit-transition: background-position .66667s cubic-bezier(.24, .22, .31, 1.07) !important;
    transition: background-position .66667s cubic-bezier(.24, .22, .31, 1.07) !important;
    background-size: 210% 100% !important;
    background-position: 99% center !important;
    background-repeat: no-repeat !important;
    cursor: pointer;

	img{
		transition: all .2s linear;
	}

    &:hover {
        background-position: 0 !important;
        text-decoration: none;
		img{
			transform: scale(1.3);
		}
    }
}

@media screen and (max-width: 1200px) {
	
	.blue-btn-radius{
		border-radius: 25px;
		padding: 0 53px;
		p{
			line-height: 50px;
			font-size: 24px;
			font-weight: 400;
		}
	}
	
	.blue-btn{
		padding: 0 15px 0 19px;
		p{
			line-height: 47px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 23px;
		}
	}
	
	.blue-btn-big{
		padding: 0 15px 0 19px;
		p{
			line-height: 47px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 23px;
		}
	}
	
	.white-btn{
		padding: 0 15px 0 19px;
		p{
			line-height: 47px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 23px;
		}
	}
	
	.blur-btn{
		padding: 0 15px 0 19px;
		p{
			line-height: 47px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 23px;
		}
	}
	
	.load-more-btn{
		padding: 0 15px 0 19px;
		p{
			line-height: 47px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 23px;
		}
	}
	
	.read-more-btn{
		p{
			line-height: 19px;
			font-size: 14px;
			font-weight: 400;
		}
		img{
			margin-left: 16px;
		}
	}
	
	.number-download{
		img{
			margin-left: 13px;
		}
	}

}