.triple-swiper {
    $transition: all .35s ease-out;
    $s-btn-s: 47px;
    position: relative;
    padding: 0px calc( #{ $s-btn-s } + 35px );
    .swiper{
        width: 100%;
        height: 100%;
    }
    .swiper-slide {
        .image-holder{
            border-radius: 5px;
            overflow: hidden;
            &>img{   
                filter: blur(5px);
                transition: $transition;
            }
        }
        .content-box{
            pointer-events: none;
            opacity: 0;
            transition: $transition;
        }
        &.swiper-slide-active{
            .image-holder>img{
                filter: blur(0px);
            }
            .content-box{
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    .image-holder{
        img{
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    .slider-button{
        &s{
            position: absolute;
            left: 0px;
            top: 50%;
            width: 100%;
            height: 0px;
            background-color: pink;
            z-index: 2;
            pointer-events: none;
        }
        pointer-events: all;
        transform: translateY( $s-btn-s / -2 );
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ) {
    .triple-swiper{
        padding: 0px;
    }
}

@media screen and ( max-width: map-get( $grid-breakpoints, sm ) - 1 ) {
    .triple-swiper{
        width: calc( 100% + ( 35px * 2 ) );
        margin-left: -35px;
    }
}