body{
    &.active{
        overflow: hidden;
    }
}
#nav{
    position: absolute;
    background-color: transparent;
    padding: 37px 0 0 0;
    top: 0;
    left: 0;
    width: 100%;
    @media screen and (min-width: 1200px) {
        height: 82px;
    }
    .nav-logo{
        position: relative;
        max-width: 180px;
        width: 100%;
        height: auto;
        margin-right: 33px;
        z-index: 99999;
        position: relative;
        img{
            width: 100%;
            height: auto;
            align-self: center;
        }
    }
    .navbar-collapse{
        display: flex !important;
        flex-basis: auto;
        z-index: 2;
    }
    .navbar-toggler{
        width: 24px;
        height: 24px;
        padding: 0;
        border: 0;
        overflow: hidden;
        img{
            position: absolute;
            transition: right .2s linear;
            &:first-child{
                width: 24px;
                height: auto;
                opacity: 1;
                right: 0px;
            }
            &:nth-child(2){
                width: 15px;
                height: 15px;
                right: -20px;
            }
        }
        &[aria-expanded="true"]{
            img{
                &:nth-child(1){
                    right: 30px;
                }
                &:nth-child(2){
                    right: 0;
                }
            }
        }
    }
    .navbar-nav{
        align-items: center;
    }
    .nav-item{
        height: 100%;
        position: relative;
        .nav-link{
            font-size: 18px;
            line-height: 24px;
            color: #FFF;
            font-weight: 200;
            z-index: 99999;
            position: relative;
            padding: 0 !important;
            span{
                margin-left: 10px;
            }
        }
    }
    .mega-menu {
        display: none;
        position: fixed;
        padding-top: 77px;
        top: 0px;
        left: 0px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #001120;
        height: 555px;
        a {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: $white;
            opacity: .5;
            transition: opacity .2s linear;
            white-space: nowrap;
            img{
                margin-left: 116px;
            }
            &:hover{
                opacity: 1;
                text-decoration: none;
            }
        }
        & > div {
            margin-left: 50%;
            padding-top: 185px;
            @media screen and (max-width: 1900px) {
                margin-left: 36.58%;
            }
        }
        .sub-item-list {
            width: 286px;
            .sub-item-title{
                position: absolute;
                left: 0;
                margin-top: -115px;
                p{
                    font-size: 60px;
                    line-height: 65px;
                    font-weight: 300;
                    white-space: nowrap;
                    color: #FFF;
                }
            }
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: rgba(#BEBEBE,0.2); 
            }
            &::-webkit-scrollbar-thumb {
                background: #858585; 
            }
            @media screen and (max-width: 1900px) {
                width: 265px;
            }
        }
        .sub-item-head {
            direction: ltr;
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            & > img {
                position: absolute;
                right: 23.1px;
                height: 9.1px;
                width: 5.7px;
                top: calc( 50% - 4.5px );
                opacity: 0;
                transition: opacity .2s linear;
            }
            &:first-of-type {
                margin-top: 11px;
            }
            &:hover {
                & > img {
                    opacity: 1;
                }
                .sub-item-body {
                    display: flex;
                    .sub-item-second{
                        display: none;
                        padding-left: 57px;
                    }
                    .sub-item{
                        a{
                            min-width: 200px;
                            margin-bottom: 20px;
                        }
                        &:hover{
                            .sub-item-second{
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
        .sub-item-body {
            display: none;
            left: calc(50% + 285px);
            max-height: calc( 100vh - 77px );
            position: fixed;
            @media screen and (max-width: 1900px) {
                left: calc(36.58% + 264px);
            }
        }
    }
    .text-area {
        position: absolute;
        width: 52%;
        left: -52%;
        top: 0;
        height: 100%;
    }
    .blue-btn-radius{
        position: relative;
        z-index: 1;
    }
    .dropdown{
        z-index: 1;
        width: 67px;
        .dropdown-toggle{
            font-size: 18px;
            line-height: 24px;
            font-weight: 200;
            color: #FFF;
			transition: all .2s linear;
            &::after{
    			transition: all .2s linear;
            }
        }
        &.show{
            background: #FFF;
            border-bottom: 1px solid #E3E4E3;
            border-radius: 10px 10px 0 0;
            padding: 9px 10px 8.5px 15px;
            .dropdown-toggle{
                color: #3C3C3C;
                &::after{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .dropdown-menu {
        background: #FFFFFF;
        margin-top: 0px !important;
        right: 0 !important;
        left: auto !important;
        padding: 15px 16px 16px 22px;
        min-width: unset !important;
        width: 100%;
        border: none !important;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #E3E4E3 !important;
        cursor: pointer;
        z-index: 99999;
        a {
            color: #1D1D1D;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 3px;
            padding: 0;
            font-weight: 100;
            &:last-child{
                margin-bottom: 0;
            }
            &:focus{
                color: #1D1D1D;
                text-decoration: none;
                background-color: transparent;
            }
            &:hover{
                color: #1D1D1D;
                text-decoration: none;
                background-color: transparent;
            }
        }
    }
    #search{
        .nav-link{
            border-bottom: 0px !important;
        }
        .mega-menu{
            justify-content: center;
            a img {
                margin-left: 0px;
            }
            .read-more-btn{
                img{
                    margin-left: 22px;
                }
            }
        }
        .input-area{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;
            width: 100%;
            max-width: 812px;
            padding-top: 0;
            margin-left: 0;
        }
        input{
            width: 100%;
            height: 70px;
            background-color: #fff;
            border: 0;
            padding-left: 21px;
            margin-right: -63px;
            background-color: #F5F5F520 !important;
            backdrop-filter: blur(30px) brightness(1.15);
            cursor: pointer;
            color: #FFF;
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: #FFF;
              font-size: 18px;
              font-weight: 200;
            }
        }
    }
}
#hamburger-menu{
    height:100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 21;
    transform: translateX(-100%);
    transition: transform .3s ease-out;
    background-color: #001120;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 113px;
    &.active{
        transform: translateX(0%);
    }
    .top{
        margin-bottom: 60px;
        padding-top: 37px;
    }
    .nav-logo{
        max-width: 142px;
        img{
            width: 100%;
            height: auto;
        }
    }
    .search-button{
        img{
            position: relative;
        }
    }
    .input-area{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 50px;
        width: 100%;
        padding-top: 0;
        margin-left: 0;
    }
    input{
        width: 100%;
        height: 70px;
        background-color: #fff;
        border: 0;
        padding-left: 21px;
        margin-right: -63px;
        background-color: #F5F5F520 !important;
        backdrop-filter: blur(30px) brightness(1.15);
        cursor: pointer;
        color: #FFF;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #FFF;
          font-size: 18px;
          font-weight: 200;
        }
    }
    .accordion-button{
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        color: #FFF;
        font-weight: 200;
        &:first-child{
            margin-top: 38px;
        }
        p{
            margin-bottom: 0;
        }
        .plus{
            display: block;
        }
        .minus{
            display: none;
        }
        &[aria-expanded="true"]{
            font-size: 28px;
            line-height: 37px;
            .plus{
                display: none;
            }
            .minus{
                display: block;
            }
        }
    }
    .accordion-body{
        a{
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 30px;
            color: #FFFFFF50;
        }
    }
    .link{
        display: flex;
        color: #FFF;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
    }
    .dropdown{
        margin-top: 60px;
        z-index: 1;
        width: 75px;
        .dropdown-toggle{
            font-size: 18px;
            line-height: 24px;
            font-weight: 200;
            color: #FFF;
			transition: all .2s linear;
            &::after{
    			transition: all .2s linear;
            }
        }
        &.show{
            background: #FFF;
            border-bottom: 1px solid #E3E4E3;
            border-radius: 10px 10px 0 0;
            padding: 9px 10px 8.5px 15px;
            .dropdown-toggle{
                color: #3C3C3C;
                &::after{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .dropdown-menu {
        background: #FFFFFF;
        margin-top: 10px !important;
        right: 0 !important;
        left: -15px !important;
        padding: 15px 16px 16px 22px;
        min-width: unset !important;
        width: 75px;
        border: none !important;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #E3E4E3 !important;
        cursor: pointer;
        z-index: 99999;
        a {
            color: #1D1D1D;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 3px;
            padding: 0;
            font-weight: 100;
            &:last-child{
                margin-bottom: 0;
            }
            &:focus{
                color: #1D1D1D;
                text-decoration: none;
                background-color: transparent;
            }
            &:hover{
                color: #1D1D1D;
                text-decoration: none;
                background-color: transparent;
            }
        }
    }
}
body{
    &.scrolled{
        .mega-menu{
            display: none !important;
        }
    }
    &.nav-white{
        #nav{
            position: relative;
            height: 130px;
            padding-top: 0;
            box-shadow: 0 1px 1px #00000016;
            .navbar-collapse{
                height: 100%;
            }
            .nav-container{
                height: 100%;
            }
            .navbar-nav{
                height: 100%;
            }
            .nav-item{
                .nav-link {
                    color: #3C3C3C;
                }
            }
            .dropdown{
                .dropdown-toggle {
                    color: #3C3C3C;
                }
            }
            .mega-menu {
                margin-top: 130px;
                padding-top: 0
            }
            @media screen and (max-width: 1200px) {
                height: unset;
                padding-top: 38px;
                padding-bottom: 31px;
            }
        }
    }
}
@import '../media/shared/nav-media';
