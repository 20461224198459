// Required
@import "node_modules/bootstrap/scss/functions";
@import "./variables";
@import "./typography";
@import "node_modules/bootstrap/scss/mixins";

// Components & Utils
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/print";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/utilities";

// Environments
@import "./env";

// Shared
@import "./modules/shared/navbar";
@import "./modules/shared/footer";
@import "./modules/shared/buttons/buttons";
@import "./modules/shared/cards/cards";
@import "./temp/temp";

// Other Modules
@import "../../base/modules/content-animation";

html,
body {
  min-width: 100%;
  min-height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
	overflow-x: hidden;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: #c7c2c2; 
		border-radius: 0px;
	}
	::-webkit-scrollbar-thumb {
		background: #808080;
		border-radius: 5px; 
	}
	::-webkit-scrollbar-thumb:hover {
		background: #808080;
	}
}

.row{
	margin: 0;
}

[data-close-button]{
	cursor: pointer;
}

// popup overlay
#popup-overlay {
    position: fixed;
    opacity: 0;
    transition: 200ms ease-in-out;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    pointer-events: none;
    cursor: pointer;
    display: none;
    &.active {
        opacity: 0.77;
        display: flex;
        pointer-events: all;
    } 
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: #7cbceb; 
	border-radius: 0px;
}
::-webkit-scrollbar-thumb {
	background: #0075C9;
	border-radius: 5px; 
}
::-webkit-scrollbar-thumb:hover {
	background: #0075C9;
}

.glide {
  .glide__arrow {
    color: $black;
  }
}

section {
  padding: 100px 0;
}

a{
	outline-offset: 0px !important;
	text-decoration: none !important;
	appearance: none !important;
	-webkit-appearance: none !important;
	outline: none !important;
	cursor: pointer !important;
	p{
		appearance: none !important;
		-webkit-appearance: none !important;
		outline: none !important;
		&:focus-visible{
			outline: -webkit-focus-ring-color auto 0px;
		}
	}
}

.dropdown-item:hover, .dropdown-item:focus {
    color: unset;
    background-color: unset;
}

button{
	outline-offset: 0px !important;
	appearance: none !important;
	-webkit-appearance: none !important;
	outline: none !important;
	cursor: pointer !important;
	p{
		appearance: none !important;
		-webkit-appearance: none !important;
		outline: none !important;
		&:focus-visible{
			outline: -webkit-focus-ring-color auto 0px;
		}
	}
	&:focus-visible {
		outline-offset: 0px !important;
	}
}

ul{
	padding: 0;
	li{
		list-style-type: none;
		&::before{
			border: 1px solid #3C3C3C;
			border-radius: 50%;
			width: 9px;
			height: 9px;
		}
	}
	&.custom-ul{
		li{
			position: relative;
			display: flex;
			margin-bottom: 22px;
			padding-left: 19px;
			p{
				margin-bottom: 0;
				font-weight: 500;
				font-size: 18px;
				line-height: 24px;
			}
			&::before{
				content: '';
				position: absolute;
				display: flex;
				left: 0;
				top: 8px;
				border: 1px solid #3C3C3C;
				border-radius: 50%;
				width: 9px;
				height: 9px;
			}
			
		}
		&-black{
			li{
				position: relative;
				display: flex;
				margin-bottom: 22px;
				padding-left: 19px;
				p{
					margin-bottom: 0;
					font-size: 18px;
					line-height: 24px;
				}
				&::before{
					content: '';
					position: absolute;
					display: flex;
					left: 0;
					top: 8px;
					background-color: #3C3C3C;
					border-radius: 50%;
					width: 9px;
					height: 9px;
				}
				
			}
		}
	}
}

table {
	width: 100%;
	border-collapse: collapse;
}
th, td {
	border: 1px solid #DDDDDD;
	padding: 15px 37px 15px 30px;
	text-align: center;
}
th {
	background-color: #F6F7F7;
}

.container-wide-left{
	max-width: 100%;
	padding-left: calc((100% - 1640px) / 2);
}

.container-wide-right{
	max-width: 100%;
	padding-right: calc((100% - 1640px) / 2);
}

@media screen and ( max-width: map-get( $grid-breakpoints, xxl ) + 1 ){
	.container-wide-left{
		padding-left: calc((100% - 1140px) / 2);
	}
	.container-wide-right{
		padding-right: calc((100% - 1140px) / 2);
	}
}

@media screen and ( max-width: map-get( $grid-breakpoints, xl ) + 1 ){
	.container-wide-left{
		padding-left: calc((100% - 960px) / 2);
	}
	.container-wide-right{
		padding-right: calc((100% - 960px) / 2);
	}
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg ) + 1 ){
	.container-wide-left{
		padding-left: calc((100% - 720px) / 2);
	}
	.container-wide-right{
		padding-right: calc((100% - 720px) / 2);
	}
	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-track {
		border-radius: 3px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 3px; 
	}
}

@media screen and ( max-width: map-get( $grid-breakpoints, md ) + 1 ){
	.container-wide-left{
		padding-left: 15px;
		margin-left: 24px;
	}
	.container-wide-right{
		padding-right: 15px;
		margin-right: 24px;
	}
}

@media screen and ( max-width: map-get( $grid-breakpoints, sm ) + 1 ){
	.container{
		padding: 0 23px;
	}
	.container-wide-left{
		padding-left: 23px;
		margin-left: 0;
	}
	.container-wide-right{
		padding-right: 23px;
		margin-right: 0;
	}
}
