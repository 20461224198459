.circle-arrow-btn{
    width: 47px;
    min-width: 47px;
    height: 47px;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all .35s ease-out;
    padding: 0 !important;
    &:hover{
        transform: scale(1.05);
    }
    img{
        width: 7px;
        height: auto;
        max-height: 100%;
        object-fit: contain;
    }
    &.arrow-left{
        img{
            transform: scaleX(-1);
        }
    }
    &.arrow-right{

    }
    &.btn-blue{
        background-color: #0075C9;
    }
    &.btn-black{
        background-color: #001120;
    }
}

.socail-circle-btn{
    width: 67px;
    height: 67px;
    min-width: 67px;
    border-radius: 50%;
    background-color: black;
    img{
        max-width: 26px;
        max-height: 26px;
    }
    &.btn-blue{
        background-color: #0075C9;
    }
    &.hover-img{
        img{
            transition: all .35s ease-out;
        }
        &:hover{
            img{
                transform: scale(1.05);
            }
        }
    }
}

.flag-lang-selector{
    .btn-lang-selector{
        padding: 7px 25px;
        border-radius: 19px;
        background-color: $white;
        border: 0px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    }
    .lang-btn{
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 6px 25px;
        img{
            width: 18px;
            height: 18px;
            min-width: 18px;
            margin-right: 8px;
        }
    }
    .dropdown-menu{
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 120px;
        border-radius: 19px;
        border: 0px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        overflow: hidden;
    }
    .dropdown-item{
        &:hover{
            background-color: $gray-100;
        }
    }
    .dropdown-toggle{
        &::after{
            transition: transform .35s ease-out;
        }
    }
    &.show{
        .dropdown-toggle{
            &::after{
                transform: scaleY(-1);
            }
        }
    }
}

.shared-background-holder{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    img{
        max-width: 100%;
        max-height: 100%;
    }
    @mixin fitPositions () {
        &-cover{
            img{
                object-fit: cover;
            }
        }
        &-contain{
            img{
                object-fit: contain;
            }
        }
        &-center{
            img{
                object-position: center;
            }
        }
        &-top-center{
            img{
                object-position: top center;
            }
        }
        &-h-100{
            img{
                min-height: 100%;
                height: 100%;
            }
        }
    }
    @media screen and ( min-width: map-get( $grid-breakpoints, lg ) ){
        &.desktop{
            @include fitPositions();
        }
    }
    @media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ){
        &.mobile{
            @include fitPositions();
        }
    }
}

.font-weight-medium{
    font-weight: 500;
}
.font-weight-black{
    font-weight: 900;
}

@media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ){
    .socail-circle-btn{
        width: 55px;
        height: 55px;
        min-width: 55px;
        img{
            max-width: 22px;
            max-height: 22px;
        }
    }
}

// brand slider
@import "../modules/brand-slider/brand-slider";

// triple slider
@import "../modules/triple-slider/triple-slider";

// tab slider
@import "../modules/tab-slider/tab-slider";