.download-card{
	position: relative;
	border-radius: 5px;
	height: 361px;
	width: 407px;
	.bg-holder{
		position: absolute;
		width: 100%;
		height: 100%;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.bottom{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 16px 36px 22px 36px;
		background-color: #00000000 !important;
		backdrop-filter: blur(30px) brightness(1.15);
		.desc-big-gray{
			margin-bottom: 13px;
		}
	}
}

.download-card-pre{
	position: relative;
	border-radius: 5px;
	height: 361px;
	width: 407px;
	padding: 37px 42px;
	background-color: #EAF4FA;
	overflow: auto;
	.desc-gray{
		color: #3C3C3C;
		margin-bottom: 11px;
	}
	.download-item{
		padding-bottom: 23px;
		margin-bottom: 19px;
		border-bottom: .5px solid #636569;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.download-card-img{
	position: relative;
	border-radius: 5px;
	height: 361px;
	width: 407px;
	padding: 22px 27px 37px 27px;
	background-color: #EAF4FA;
	.img-holder{
		width: 100%;
		height: 100%;
		img{
			width: 100%;
			height: 100%;
			@media screen and (max-width: 1200px) {
				object-fit: contain;
			}
		}
	}
}

.left-right-card-green{
	.left{
		position: relative;
		z-index: 1;
		min-width: 618px;
		max-width: 618px;
		background-color: #1E3100;
		border-radius: 5px;
		padding: 53px 76px 93px 80px;
		.title-white{
			margin-top: 32px;
			margin-bottom: 25px;
		}
		.desc-white{
			margin-bottom: 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		a{
			margin-top: 40px;
		}
	}
	.right{
		margin-left: -437px;
		height: 724px;
		width: 100%;
	}
}

.left-right-card{
	.left{
		margin-right: -400px;
		height: 642px;
		width: 100%;
	}
	.right{
		position: relative;
		z-index: 1;
		min-width: 758px;
		max-width: 758px;
		background-color: #F7F7F8;
		border-radius: 5px;
		padding: 84px 216px 135px 80px;
		.number-big{
			position: absolute;
			right: 46px;
			top: 8.5px;
			z-index: -1;
		}
		.title-gray{
			margin-bottom: 25px;
		}
		.desc-gray{
			margin-bottom: 41px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
		}
	}
}

.left-right-card-reverse{
	.left{
		position: relative;
		z-index: 1;
		min-width: 618px;
		max-width: 618px;
		background-color: #F7F7F8;
		border-radius: 5px;
		padding: 104px 76px 131px 80px;
		.title-gray{
			margin-bottom: 25px;
		}
		.desc-gray{
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 8;
			-webkit-box-orient: vertical;
		}
	}
	.right{
		margin-left: -437px;
		height: 724px;
		width: 100%;
	}
}

.member-card{
	&:hover{
		img{
			transform: scale(1.1);
		}
	}
	.img-holder{
		width: 100%;	
		height: 523px;
		margin-bottom: 20px;
		border-radius: 5px;
		overflow: hidden;
		img{
			transition: all .2s linear;
		}
	}
	.desc-gray{
		margin-bottom: 41px;
	}
}

.port-card{
	&:hover{
		img{
			transform: scale(1.1);
		}
	}
	.img-holder{
		width: 100%;	
		height: 460px;
		margin-bottom: 20px;
		border-radius: 5px;
		overflow: hidden;
		img{
			transition: all .2s linear;
		}
	}
	.name{
		margin-bottom: 10px;
	}
	.desc-gray{
		margin-bottom: 41px;
	}
}

.media-card{
	display: flex;
	width: 100%;
	height: 572px;
	&:hover{
		img{
			transform: scale(1.1);
		}
	}
	.bg-holder{
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		&::after{
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			display: flex;
			width: 100%;
			height: 100%;
			background: rgb(255,255,255);
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.8) 100%);
		}
		img{
			transition: all .3s linear;
		}
	}
	.text-holder{
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: absolute;
		padding: 0 78px 78px 68px;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		.header-white-big-medium{
			margin-bottom: 20px;
		}
		.title-white{
			margin-bottom: 25px;
			max-width: 76%;
		}
		.blur-btn{
			background-color: #F5F5F550 !important;
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1599px ) {

	.img-text-text .left {
		height: 450px !important;
	}

	.member-card .img-holder {
		height: 400px;
	}

	.port-card .img-holder {
		height: 350px;
	}

	.left-right-card-reverse{
		.right {
			height: 600px;
		}
		.left {
			padding: 80px 56px 100px 60px;
		}
	}

}

@media screen and (max-width: 1200px) {

	.download-card{
		width: 100%;
		.bottom{
			padding: 16px 36px 22px 25px;
		}
	}

	.download-card-pre{
		width: 100%;
		height: unset;
		padding: 38px 32px;
		.desc-gray{
			margin-bottom: 14px;
		}
		.download-item{
			padding-bottom: 24px;
			margin-bottom: 21px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}

	.download-card-img{
		width: 100%;
		padding: 29px 25px;
	}

	.left-right-card-green{
		flex-direction: column-reverse;
		.left{
			min-width: unset;
			max-width: unset;
			width: calc( 100% - 60px );
			padding: 27px 33px 38px 31px;
			.document{
				width: 20px;
			}
			.title-white{
				margin-top: 18px;
				margin-bottom: 23px;
				font-size: 24px;
			}
			.desc-white{
				margin-bottom: 0;
			}
			a{
				margin-top: 20px;
			}
		}
		.right{
			margin-bottom: -60px;
			margin-left: 0px;
			height: 210px;
			width: 100%;
		}
	}

	.left-right-card{
		flex-direction: column;
		.left{
			margin-right: 0px;
			height: 342px;
			margin-bottom: 18px;
		}
		.right{
			min-width: unset;
			max-width: unset;
			background-color: unset;
			border-radius: unset;
			padding: 0px;
			.number-big{
				position: absolute;
				right: 0px;
				top: -15px;
			}
			.title-gray{
				margin-bottom: 13px;
			}
			.desc-gray{
				-webkit-line-clamp: 8;
			}
		}
	}

	.left-right-card-reverse{
		flex-direction: column-reverse;
		.left{
			min-width: unset;
			max-width: unset;
			background-color: unset;
			border-radius: unset;
			padding: 0px;
			.title-gray{
				margin-bottom: 18px;
			}
			.desc-gray{
				overflow: unset;
				display: unset;
				-webkit-line-clamp: unset;
				-webkit-box-orient: unset;
			}
		}
		.right{
			margin-left: 0px;
			height: 342px;
			margin-bottom: 20px;
		}
	}

	.member-card{
		.img-holder{
			height: 449px;
		}
	}

	.port-card{
		.img-holder{
			height: 242px;
		}
		.name{
			margin-bottom: 14px;
		}
	}

	.media-card{
		height: 278px;
		.text-holder{
			padding: 0 35px 29px 20px;
			.header-white-big-medium {
				font-size: 16px;
				line-height: 21px;
				margin-bottom: 10px;
			}
			.title-white{
				margin-bottom: 14px;
				max-width: 100%;
				font-size: 16px;
				line-height: 24px;
			}
			.date-white{
				margin-bottom: 0;
			}
			.blur-btn{
				display: none;
			}
		}
	}

}