$env: "production";
// Global
@import "../../base/global";

.page-404{
    @media screen and ( min-width: map-get( $grid-breakpoints, md ) ){
        min-height: calc( 100vh - 130px );
    }
    .text-404-L{
        font-size: 260px;
        line-height: 280px;
    }
    .text-404-M{
        font-size: 40px;
        line-height: 45px;
    }
    .text-blue{
        color: #022F54;
    }
    .text-light-blue{
        color: #0075C9;
        &:hover{
            color: darken(#0075C9,5);
        }
    }
    form{
        input{
            width: 100%;
            background-color: #022F54;
            color: $white;
            font-size: 18px;
            line-height: 24px;
            border: 0px;
            padding: 28px 70px 28px 23px;
            &:focus{
                outline: none;
            }
            &::placeholder{
                color: $white;
            }
        }
        button{
            position: absolute;
            right: 0px;
            top: 0px;
            margin: 0px;
            padding: 0px;
            border: 0px;
            width: 70px;
            height: 100%;
            background-color: transparent;
            img{
                width: 26px;
                height: 26px;
                min-width: 26px;
                transition: all .35s ease-out;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
        }
    }
    @media screen and ( max-width: map-get( $grid-breakpoints, lg ) - 1 ){
        .text-404-L{
            font-size: 133px;
            line-height: 140px;
        }
        .text-404-M{
            font-size: 18px;
            line-height: 21px;
        }
        form{
            input{
                font-size: 16px;
                line-height: 20px;
                padding: 20px 60px 20px 20px;
            }
            button{
                width: 60px;
                img{
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                }
            }
        }
    }
    @media screen and ( max-width: map-get( $grid-breakpoints, md ) - 1 ){
        padding: 60px 0px;
    }
}
