.brand-slider{
    .glide__slide{
        height: auto;
        &:hover{
            .brand-holder > img {
                filter: grayscale(0);
            }
        }
    }
    .brand-holder{
        height: 100%;
        img{
            filter: grayscale(1);
            max-width: 100%;
            object-fit: contain;
            object-position: center;
            transition: all .35s ease-out;
        }
    }
    &.in-container{
        width: 100vw;
        margin-left: calc( ( 100vw - 100% ) / -2 );
    }
}
